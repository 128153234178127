const rtp_resizer = require('./text-resizing.js'),
    rtp_text_only = require('./text-only.js'),
    rtp_forms = require('./forms.js'),
    rtp_page_reload = require('./page-reload'),
    rtp_expand = require('./expand.js'),
    rtp_gtag = require('./gtag-events.js');

document.addEventListener('DOMContentLoaded', () => {
    // Set up text resizing
    rtp_resizer.setup();

    // Add text-only toggle
    rtp_text_only.setup();

    // Setup form elements (radios & dropdown - list view), nicer reload
    rtp_forms.setup();

    rtp_page_reload.setup();

    // Setup expanding blocks
    rtp_expand.setup();

    // Setup sending gtag info
    rtp_gtag.setup();

    // Adjust aria labels for story list links to contain whichever text snippet is displayed
    const story_cards = document.querySelectorAll('.story-list__card-inner');

    for (let i = 0; i < story_cards.length; i += 1) {
        const this_card = story_cards[i],
            this_text = this_card.querySelector('.story-list__text').innerText,
            this_link = this_card.querySelector('.story-list__link');

        if (this_link && this_text) {
            this_link.setAttribute('aria-label', `${this_text} - Read more`);
        }
    }

    // Fade in messages
    const message = document.querySelector('.message');

    if (message) {
        message.classList.add('faded-out');

        setTimeout(() => {
            message.classList.remove('faded-out');
        }, 50);
    }
});
