// RTP resizer should mimic functionality on http://righttoparticipate.org
// of the Zeno font resizer. It should set & read from the same cookie so
// that font preferences will be smoothly shared across subdomains
const rtp_resizer = {
    // Inital values from http://righttoparticipate.org
    max_value: 40,
    min_value: 10,
    step_increase: 5,
    target_id: 'innerbody',
    cookie_name: 'fontSize',
    cookie_time: 31,

    current_value: 16,
    reset_value: 16,

    setup() {
        const pre_set_size = rtp_resizer.getCookie();

        if (pre_set_size) {
            const font_size_int = parseInt(pre_set_size, 10);
            rtp_resizer.current_value = font_size_int;
            rtp_resizer.setFontSize();

            // In case max value changes in the future, reset according to new info
            if (font_size_int > rtp_resizer.max_value) {
                rtp_resizer.max_value = font_size_int;
            }
        }

        rtp_resizer.addEventListeners();
    },

    addEventListeners() {
        const increment_btn = document.querySelector('.rtp_font_resizer_add'),
            reset_btn = document.querySelector('.rtp_font_resizer_reset');

        if (increment_btn) {
            increment_btn.addEventListener('click', rtp_resizer.increment);
        }

        if (reset_btn) {
            reset_btn.addEventListener('click', rtp_resizer.reset);
        }
    },

    increment(e) {
        e.preventDefault();

        if (rtp_resizer.current_value + rtp_resizer.step_increase <= rtp_resizer.max_value) {
            rtp_resizer.current_value += rtp_resizer.step_increase;
            rtp_resizer.setFontSize();
            rtp_resizer.setCookie();
        }
    },

    reset(e) {
        e.preventDefault();

        rtp_resizer.current_value = rtp_resizer.reset_value;
        rtp_resizer.setFontSize();
        rtp_resizer.setCookie();
    },

    setFontSize() {
        const this_element = document.querySelector(`#${rtp_resizer.target_id}`);
        let innerbody_class = '';

        // Body class is additional to functionality on original site, but needed
        // to nicely display list grid at all text sizes
        if (rtp_resizer.current_value > 25 && rtp_resizer.current_value < 35) {
            innerbody_class = 'med-large-text';
        } else if (rtp_resizer.current_value >= 35) {
            innerbody_class = 'large-text';
        }

        if (this_element) {
            this_element.className = innerbody_class;
            this_element.style.fontSize = `${rtp_resizer.current_value}px`;
        }
    },

    getCookie() {
        const cookie = document.cookie.match(`(^|;) ?${rtp_resizer.cookie_name}=([^;]*)(;|$)`);
        return cookie ? cookie[2] : null;
    },

    setCookie() {
        const d = new Date();
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * rtp_resizer.cookie_time);
        const expiry_string = d.toUTCString();
        let domain_name = window.location.hostname;

        // Setting domain should ensure this is carried across to main site
        if (domain_name.match('righttoparticipate.org')) {
            domain_name = 'righttoparticipate.org';
        }

        document.cookie = `${rtp_resizer.cookie_name}=${rtp_resizer.current_value};path=/;expires=${expiry_string};domain=${domain_name}`;
    },
};

module.exports = rtp_resizer;
