// Match text only functionality from http://righttoparticipate.org/
// Styles are removed and images hidden, but video is still shown
// (Some element-specific extra functionality)
const rtp_text_only = {
    main_stylesheet_id: 'rtp-main-styles',
    is_text_only: false,

    setup() {
        const this_button = document.querySelector('.topnav__text-only a');

        this_button.addEventListener('click', rtp_text_only.makeTextOnly);
    },

    makeTextOnly(e) {
        e.preventDefault();

        if (rtp_text_only.is_text_only === false) {
            rtp_text_only.is_text_only = true;

            const main_styles = document.querySelector(`#${rtp_text_only.main_stylesheet_id}`),
                images = document.querySelectorAll('img'),
                svgs = document.querySelectorAll('svg.icon');

            if (main_styles) {
                main_styles.disabled = true;
            }

            rtp_text_only.hideElements({ element_set: images, look_for_alt: true });
            rtp_text_only.hideElements({ element_set: svgs, look_for_alt: false });
            rtp_text_only.storyListLinks();
            rtp_text_only.updateExpanders();
        }
    },

    hideElements({ element_set, look_for_alt }) {
        // If image holds non-decorative content (and so has an alt tag)
        // replace this with a text element
        for (let i = 0; i < element_set.length; i += 1) {
            const this_el = element_set[i];
            this_el.style.display = 'none';

            if (look_for_alt) {
                const this_alt = this_el.getAttribute('alt');

                if (this_alt && this_alt !== '') {
                    const new_text = document.createElement('p');
                    new_text.innerHTML = this_alt;
                    this_el.parentNode.insertBefore(new_text, this_el);
                }
            }
        }
    },

    storyListLinks() {
        // Outside text only mode, this has no content, only an aria-label, so that it
        // will cover the whole card (for mouse users) and avoid multiple links per card
        // (for keyboard users), but screenreader users can still tab through each inner content
        // element for more detailed text. In text only view - wrap link around only 'Read more' CTA
        // (& hide duplicates)
        const readMoreCTAs = document.querySelectorAll('.story-list__cta'),
            readMoreLinks = document.querySelectorAll('.story-list__link');

        for (let i = 0; i < readMoreLinks.length; i += 1) {
            readMoreLinks[i].innerText = 'Read story';
        }

        rtp_text_only.hideElements({ element_set: readMoreCTAs, look_for_alt: false });
    },

    updateExpanders() {
        // Content in expand sections will all be shown in text only view, so remove toggle buttons
        // & replace with 'transcript' label. Content must not have 'aria-hidden'=true
        const expand_buttons = document.querySelectorAll('.expand-button'),
            transcripts = document.querySelectorAll('.expand-transcript');

        rtp_text_only.hideElements({ element_set: expand_buttons, look_for_alt: false });

        for (let i = 0; i < transcripts.length; i += 1) {
            const this_transcript = transcripts[i],
                transcript_title = document.createElement('p');

            transcript_title.innerText = 'Transcript:';
            this_transcript.setAttribute('aria-hidden', 'false');
            this_transcript.parentNode.insertBefore(transcript_title, this_transcript);
        }
    },
};

module.exports = rtp_text_only;
