// Set up tracking of specific links using gtagjs
const rtp_gtag = {
    current_link_href: '',
    link_followed: false,
    timeout_ms: 1000,

    setup() {
        const take_action_link = document.querySelector('.rtp-link-take-action'),
            clap_link = document.querySelector('.rtp-link-clap'),
            share_links = document.querySelectorAll('.rtp-link-share');

        // Track shares
        for (let i = 0; i < share_links.length; i += 1) {
            rtp_gtag.setupEventListener({ element: share_links[i], gtag_event: 'share', gtag_label: 'Story share' });
        }

        // Track clicks on 'take action' link at story end
        if (take_action_link) {
            rtp_gtag.setupEventListener({
                element: take_action_link,
                gtag_event: 'view_item',
                gtag_label: 'Stories to resources link',
            });
        }

        // Track claps
        if (clap_link) {
            rtp_gtag.setupEventListener({ element: clap_link, gtag_event: 'view_item', gtag_label: 'Story clap' });
        }
    },

    setupEventListener({ element, gtag_event, gtag_label }) {
        element.addEventListener('click', (e) => {
            e.preventDefault();

            // Store href of current link
            rtp_gtag.current_link_href = element.href;

            // Timeout ensure link works if gtag has not loaded
            setTimeout(rtp_gtag.followLink, rtp_gtag.timeout_ms);

            // (gtags should be ok without any custom settings to track across the subdomain
            // where this site will sit)
            // https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id

            // set gtags event: https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data
            // https://developers.google.com/analytics/devguides/collection/gtagjs/events
            // gtag_event and event_category come from the standard list of events
            // labels should give enough info to the person looking at the analytics to
            // determine the exact action recorded
            window.gtag('event', gtag_event, {
                event_category: 'engagement',
                event_label: gtag_label,
                event_callback: rtp_gtag.followLink,
            });
        });
    },

    followLink() {
        // Don't fire twice
        // No need to reset rtp_gtag.link_followed as page will reload / go elsewhere
        if (!rtp_gtag.link_followed) {
            rtp_gtag.link_followed = true;
            window.location.replace(rtp_gtag.current_link_href);
        }
    },
};

module.exports = rtp_gtag;
