(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
const rtp_expand = {
    setup() {
        const expand_btns = document.querySelectorAll('.expand-button');

        for (let i = 0; i < expand_btns.length; i += 1) {
            const this_btn = expand_btns[i],
                this_content = this_btn.nextElementSibling;
            if (this_content) {
                this_content.id = `expander-${i}`;
                this_content.setAttribute('aria-hidden', 'true');
                this_btn.setAttribute('aria-expanded', 'false');
                this_btn.setAttribute('aria-controls', `expander-${i}`);
                this_btn.addEventListener('click', rtp_expand.toggleExpand);
            }
        }
    },

    toggleExpand(e) {
        const this_btn = e.target,
            is_expanded = this_btn.getAttribute('aria-expanded'),
            content_id = this_btn.getAttribute('aria-controls'),
            this_content = document.querySelector(`#${content_id}`);

        if (is_expanded === 'true' && this_content) {
            this_btn.setAttribute('aria-expanded', 'false');
            this_content.setAttribute('aria-hidden', 'true');
        } else {
            this_btn.setAttribute('aria-expanded', 'true');
            this_content.setAttribute('aria-hidden', 'false');
        }
    },
};

module.exports = rtp_expand;

},{}],2:[function(require,module,exports){
const rtp_forms = {
    dropdown_aria_base: 'Show ordering options. Current: ',
    dropdown_time_ms: 300,
    dropdown_timeout: null,
    submit_btn: null,
    mouse_touch_timeout: null,

    setup() {
        const filter_radios = document.querySelector('.filter__filter-by'),
            order_radios = document.querySelector('.filter__order-by');
        rtp_forms.submit_btn = document.querySelector('.filters input[type=submit]');

        if (filter_radios && order_radios && rtp_forms.submit_btn) {
            rtp_forms.setupRadios(filter_radios);
            rtp_forms.setupRadios(order_radios);
            // Because the native 'select' element doesn't read out options as you navigate through
            rtp_forms.setupDropdown(order_radios);
        }

        rtp_forms.commentsFormSetup();
    },

    commentsFormSetup() {
        // Specific tweaks for comments form
        const comment_body_input = document.querySelector('.story-detail__comments #id_body'),
            comment_body_label = document.querySelector('.story-detail__comments label[for=id_body]'),
            comment_name_input = document.querySelector('.story-detail__comments #id_name'),
            comment_name_label = document.querySelector('.story-detail__comments label[for=id_name]');

        if (comment_body_input) {
            comment_body_input.placeholder = 'Write a comment...';

            if (comment_body_label) {
                comment_body_label.classList.add('hidden');
            }
        }

        if (comment_name_input) {
            comment_name_input.placeholder = 'Your name (optional)';

            if (comment_name_label) {
                comment_name_label.classList.add('hidden');
            }
        }
    },

    setupDropdown(order_radios) {
        const dropdown_btn = order_radios.querySelector('.dropdown-toggle'),
            dropdown_content = dropdown_btn.nextElementSibling,
            radios = order_radios.querySelectorAll('input[type=radio]');

        for (let i = 0; i < radios.length; i += 1) {
            radios[i].addEventListener('change', rtp_forms.setDropdownText);
        }

        if (dropdown_content) {
            dropdown_btn.setAttribute('aria-expanded', 'false');
            dropdown_btn.setAttribute('aria-haspopup', 'true');
            dropdown_btn.addEventListener('click', rtp_forms.toggleDropdown);
            dropdown_content.classList.add('hidden');
        }

        rtp_forms.setDropdownText();
    },

    setDropdownText() {
        // Reflect changes on hidden radios
        const checked_order_radio = document.querySelector('.filter__order-by input[type=radio]:checked'),
            first_option = document.querySelectorAll('.filter__order-by input[type=radio]')[0],
            dropdown_btn = document.querySelector('.filter__order-by .dropdown-toggle');
        let dropdown_text = '';

        // Check for selected radio or get text from first option
        if (checked_order_radio) {
            dropdown_text = checked_order_radio.parentNode.innerText;
        } else {
            dropdown_text = first_option.parentNode.innerText;
        }

        dropdown_btn.setAttribute('aria-label', `${rtp_forms.dropdown_aria_base}${dropdown_text}`);
        dropdown_btn.innerHTML = dropdown_text;
    },

    toggleDropdown(e) {
        const this_button = e.target,
            is_expanded = this_button.getAttribute('aria-expanded'),
            this_content = this_button.nextElementSibling;
        rtp_forms.clearDropdownTimeout();

        if (is_expanded === 'true') {
            this_button.setAttribute('aria-expanded', 'false');
            this_content.classList.remove('filter__slide-in');

            // Timeout allows for slide-in class but also using display:none to avoid needing
            // to loop through and remove tabindex on inputs hidden by other means
            rtp_forms.dropdown_timeout = setTimeout(() => {
                this_content.classList.add('hidden');
            }, rtp_forms.dropdown_time_ms);
        } else {
            this_button.setAttribute('aria-expanded', 'true');
            this_content.classList.remove('hidden');
            setTimeout(() => {
                this_content.classList.add('filter__slide-in');
            }, 0);
        }
    },

    clearDropdownTimeout() {
        if (rtp_forms.dropdown_timeout !== null) {
            clearTimeout(rtp_forms.dropdown_timeout);
            rtp_forms.dropdown_timeout = null;
        }
    },

    setupRadios(radio_group) {
        const radios = radio_group.querySelectorAll('input[type=radio]');

        for (let i = 0; i < radios.length; i += 1) {
            // JS needed because input is child of label rather than nextSibling element
            radios[i].addEventListener('change', () => {
                rtp_forms.radioChange(radio_group, 'checked');
            });

            radios[i].addEventListener('focus', () => {
                rtp_forms.radioChange(radio_group, 'focus');
            });

            radios[i].addEventListener('blur', () => {
                rtp_forms.radioChange(radio_group, 'focus');
            });

            radios[i].parentNode.addEventListener('mouseup', rtp_forms.mouseTouchSubmit);
            radios[i].parentNode.addEventListener('touchend', rtp_forms.mouseTouchSubmit);
        }

        rtp_forms.showInitialValue(radios, radio_group);
    },

    showInitialValue(radios, radio_group) {
        // Set initial radio checked values either according to url params, or first option
        let selected_val = null,
            param_name = '';

        if (radio_group.classList.contains('filter__order-by')) {
            param_name = 'order_by';
        } else {
            param_name = 'area';
        }

        selected_val = rtp_forms.getParam(param_name);
        let id_val = null;

        if (selected_val) {
            if (param_name === 'area') {
                selected_val = parseInt(selected_val, 10);
                id_val = selected_val + 1;
            } else {
                id_val = selected_val === '-created_at' ? 1 : 2;
            }
        }

        if (id_val) {
            const selected_radio_unchecked = document.querySelector(`#id_${param_name}_${id_val}:not(:checked)`),
                selected_radio = document.querySelector(`#id_${param_name}_${id_val}`);

            // Either select radio, or if selected, reflect in parentNode
            // On reload (one set of radios keeps its selection, the other not)
            if (selected_radio_unchecked) {
                selected_radio.parentNode.click();
            } else if (selected_radio) {
                selected_radio.parentNode.classList.add('checked');
            }
        } else {
            radios[0].parentNode.click();
        }
    },

    getParam(param_name) {
        const params = window.location.search,
            param_array = params.split(/[?&]/);
        let this_value = null;

        for (let i = 0; i < param_array.length; i += 1) {
            if (param_array[i].indexOf(param_name) > -1) {
                this_value = param_array[i].split('=')[1];
            }
        }

        return this_value;
    },

    radioChange(radio_group, change_type) {
        const unchecked_or_unfocused_radios = radio_group.querySelectorAll(`input[type=radio]:not(:${change_type})`),
            checked_or_focused_radio = radio_group.querySelector(`input[type=radio]:${change_type}`);

        for (let i = 0; i < unchecked_or_unfocused_radios.length; i += 1) {
            unchecked_or_unfocused_radios[i].parentNode.classList.remove(change_type);
        }

        if (checked_or_focused_radio) {
            checked_or_focused_radio.parentNode.classList.add(change_type);
        }
    },

    mouseTouchSubmit(e) {
        e.stopPropagation();

        // Only respond to mouseup or touchend, not both
        if (!rtp_forms.mouse_touch_timeout) {
            // On mouseup only, submit the form (as submit button is hidden for non-keyboard users)
            const results_holder = document.querySelector('.story-list__holder');

            if (results_holder) {
                results_holder.classList.add('faded-out');
            }

            // Allow time for fade-out, storing of selected values
            rtp_forms.mouse_touch_timeout = setTimeout(() => {
                rtp_forms.mouse_touch_timeout = null;

                if (rtp_forms.submit_btn) {
                    rtp_forms.submit_btn.click();
                }
            }, 150);
        }
    },
};

module.exports = rtp_forms;

},{}],3:[function(require,module,exports){
// Set up tracking of specific links using gtagjs
const rtp_gtag = {
    current_link_href: '',
    link_followed: false,
    timeout_ms: 1000,

    setup() {
        const take_action_link = document.querySelector('.rtp-link-take-action'),
            clap_link = document.querySelector('.rtp-link-clap'),
            share_links = document.querySelectorAll('.rtp-link-share');

        // Track shares
        for (let i = 0; i < share_links.length; i += 1) {
            rtp_gtag.setupEventListener({ element: share_links[i], gtag_event: 'share', gtag_label: 'Story share' });
        }

        // Track clicks on 'take action' link at story end
        if (take_action_link) {
            rtp_gtag.setupEventListener({
                element: take_action_link,
                gtag_event: 'view_item',
                gtag_label: 'Stories to resources link',
            });
        }

        // Track claps
        if (clap_link) {
            rtp_gtag.setupEventListener({ element: clap_link, gtag_event: 'view_item', gtag_label: 'Story clap' });
        }
    },

    setupEventListener({ element, gtag_event, gtag_label }) {
        element.addEventListener('click', (e) => {
            e.preventDefault();

            // Store href of current link
            rtp_gtag.current_link_href = element.href;

            // Timeout ensure link works if gtag has not loaded
            setTimeout(rtp_gtag.followLink, rtp_gtag.timeout_ms);

            // (gtags should be ok without any custom settings to track across the subdomain
            // where this site will sit)
            // https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id

            // set gtags event: https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data
            // https://developers.google.com/analytics/devguides/collection/gtagjs/events
            // gtag_event and event_category come from the standard list of events
            // labels should give enough info to the person looking at the analytics to
            // determine the exact action recorded
            window.gtag('event', gtag_event, {
                event_category: 'engagement',
                event_label: gtag_label,
                event_callback: rtp_gtag.followLink,
            });
        });
    },

    followLink() {
        // Don't fire twice
        // No need to reset rtp_gtag.link_followed as page will reload / go elsewhere
        if (!rtp_gtag.link_followed) {
            rtp_gtag.link_followed = true;
            window.location.replace(rtp_gtag.current_link_href);
        }
    },
};

module.exports = rtp_gtag;

},{}],4:[function(require,module,exports){
const rtp_resizer = require('./text-resizing.js'),
    rtp_text_only = require('./text-only.js'),
    rtp_forms = require('./forms.js'),
    rtp_page_reload = require('./page-reload'),
    rtp_expand = require('./expand.js'),
    rtp_gtag = require('./gtag-events.js');

document.addEventListener('DOMContentLoaded', () => {
    // Set up text resizing
    rtp_resizer.setup();

    // Add text-only toggle
    rtp_text_only.setup();

    // Setup form elements (radios & dropdown - list view), nicer reload
    rtp_forms.setup();

    rtp_page_reload.setup();

    // Setup expanding blocks
    rtp_expand.setup();

    // Setup sending gtag info
    rtp_gtag.setup();

    // Adjust aria labels for story list links to contain whichever text snippet is displayed
    const story_cards = document.querySelectorAll('.story-list__card-inner');

    for (let i = 0; i < story_cards.length; i += 1) {
        const this_card = story_cards[i],
            this_text = this_card.querySelector('.story-list__text').innerText,
            this_link = this_card.querySelector('.story-list__link');

        if (this_link && this_text) {
            this_link.setAttribute('aria-label', `${this_text} - Read more`);
        }
    }

    // Fade in messages
    const message = document.querySelector('.message');

    if (message) {
        message.classList.add('faded-out');

        setTimeout(() => {
            message.classList.remove('faded-out');
        }, 50);
    }
});

},{"./expand.js":1,"./forms.js":2,"./gtag-events.js":3,"./page-reload":5,"./text-only.js":6,"./text-resizing.js":7}],5:[function(require,module,exports){
const rtp_page_reload = {

    setup() {
        const reload_links = document.querySelectorAll('a.page-reload-link');

        for (let i = 0; i < reload_links.length; i += 1) {
            const this_link = reload_links[i],
                this_href = this_link.href,
                this_data_attr = this_link.getAttribute('data-target');

            this_link.id = `reload_link_${i}`;

            this_link.addEventListener('click', (e) => {
                // Prevent initial reload, store focus & scrollTop info, fade content slightly
                // and re-allow reload
                e.preventDefault();
                rtp_page_reload.storeInfo(this_link.id, this_data_attr);
                rtp_page_reload.fadeOutContent();

                // If this link is also being tracked by gtag, this will be handled separately

                if (this_link.className.indexOf('rtp-link') === -1) {
                    setTimeout(() => {
                        window.location.replace(this_href);
                    }, 0);
                }
            });
        }

        // Selected radio changes should also store focus element and scrollTop,
        // but no immediate reload
        rtp_page_reload.addReloadToRadios();

        // Check to see if info about focused element & scroll pos is stored
        rtp_page_reload.checkStoredInfo();
    },

    storeInfo(this_link_id, this_data_attr) {
        // If link has a data-target, store this id rather than link id - this
        // is where focus should go on page reload (e.g. for pagination buttons)
        // at page bottom, target story list holder
        const id_to_store = this_data_attr || this_link_id;

        if (rtp_page_reload.sessionStorageAvailable) {
            rtp_page_reload.saveFocusElement(id_to_store);

            rtp_page_reload.savePageScrollPos(this_data_attr);
        }
    },

    addReloadToRadios() {
        const radios = document.querySelectorAll('.filters input[type=radio]');

        for (let i = 0; i < radios.length; i += 1) {
            const this_radio = radios[i],
                this_id = this_radio.id;

            this_radio.addEventListener('change', () => {
                rtp_page_reload.storeInfo(this_id);
            });
        }
    },

    fadeOutContent() {
        const body = document.querySelector('body');

        if (body) {
            body.classList.add('reload-fade');
        }
    },

    saveFocusElement(focused_link_id) {
        window.sessionStorage.setItem('rol_focused_el', focused_link_id);
    },

    savePageScrollPos(possible_alternative_target_el_id) {
        let scroll_pos = window.pageYOffset || document.documentElement.scrollTop;

        if (possible_alternative_target_el_id) {
            const target_el = document.querySelector(`#${possible_alternative_target_el_id}`);

            if (target_el) {
                const bounding_rect = target_el.getBoundingClientRect(),
                    doc_scroll_top = window.pageYOffset || document.documentElement.scrollTop;
                scroll_pos = bounding_rect.top + doc_scroll_top;
            }
        }

        window.sessionStorage.setItem('rol_scroll_top', scroll_pos);
    },

    checkStoredInfo() {
        if (rtp_page_reload.sessionStorageAvailable) {
            const scroll_top = window.sessionStorage.getItem('rol_scroll_top'),
                focus_el = window.sessionStorage.getItem('rol_focused_el');

            if (scroll_top || focus_el) {
                rtp_page_reload.fadeInListHolder();
            }

            if (scroll_top) {
                document.documentElement.scrollTop = scroll_top;
                document.body.scrollTop = scroll_top;
                window.sessionStorage.setItem('rol_scroll_top', null);
            }

            setTimeout(() => {
                if (focus_el && document.querySelector(`#${focus_el}`)) {
                    document.querySelector(`#${focus_el}`).focus();
                    window.sessionStorage.setItem('rol_focused_el', null);
                }
            }, 0);
        }
    },

    fadeInListHolder() {
        // If on list view page and fetching new content
        const results_holder = document.querySelector('.story-list__holder');

        if (results_holder) {
            results_holder.classList.add('faded-out');
            results_holder.classList.add('no-transition');

            setTimeout(() => {
                results_holder.classList.remove('faded-out');
                results_holder.classList.remove('no-transition');
            }, 0);
        }
    },

    sessionStorageAvailable() {
        try {
            const storage = window.sessionStorage,
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return false;
        }
    },
};

module.exports = rtp_page_reload;

},{}],6:[function(require,module,exports){
// Match text only functionality from http://righttoparticipate.org/
// Styles are removed and images hidden, but video is still shown
// (Some element-specific extra functionality)
const rtp_text_only = {
    main_stylesheet_id: 'rtp-main-styles',
    is_text_only: false,

    setup() {
        const this_button = document.querySelector('.topnav__text-only a');

        this_button.addEventListener('click', rtp_text_only.makeTextOnly);
    },

    makeTextOnly(e) {
        e.preventDefault();

        if (rtp_text_only.is_text_only === false) {
            rtp_text_only.is_text_only = true;

            const main_styles = document.querySelector(`#${rtp_text_only.main_stylesheet_id}`),
                images = document.querySelectorAll('img'),
                svgs = document.querySelectorAll('svg.icon');

            if (main_styles) {
                main_styles.disabled = true;
            }

            rtp_text_only.hideElements({ element_set: images, look_for_alt: true });
            rtp_text_only.hideElements({ element_set: svgs, look_for_alt: false });
            rtp_text_only.storyListLinks();
            rtp_text_only.updateExpanders();
        }
    },

    hideElements({ element_set, look_for_alt }) {
        // If image holds non-decorative content (and so has an alt tag)
        // replace this with a text element
        for (let i = 0; i < element_set.length; i += 1) {
            const this_el = element_set[i];
            this_el.style.display = 'none';

            if (look_for_alt) {
                const this_alt = this_el.getAttribute('alt');

                if (this_alt && this_alt !== '') {
                    const new_text = document.createElement('p');
                    new_text.innerHTML = this_alt;
                    this_el.parentNode.insertBefore(new_text, this_el);
                }
            }
        }
    },

    storyListLinks() {
        // Outside text only mode, this has no content, only an aria-label, so that it
        // will cover the whole card (for mouse users) and avoid multiple links per card
        // (for keyboard users), but screenreader users can still tab through each inner content
        // element for more detailed text. In text only view - wrap link around only 'Read more' CTA
        // (& hide duplicates)
        const readMoreCTAs = document.querySelectorAll('.story-list__cta'),
            readMoreLinks = document.querySelectorAll('.story-list__link');

        for (let i = 0; i < readMoreLinks.length; i += 1) {
            readMoreLinks[i].innerText = 'Read story';
        }

        rtp_text_only.hideElements({ element_set: readMoreCTAs, look_for_alt: false });
    },

    updateExpanders() {
        // Content in expand sections will all be shown in text only view, so remove toggle buttons
        // & replace with 'transcript' label. Content must not have 'aria-hidden'=true
        const expand_buttons = document.querySelectorAll('.expand-button'),
            transcripts = document.querySelectorAll('.expand-transcript');

        rtp_text_only.hideElements({ element_set: expand_buttons, look_for_alt: false });

        for (let i = 0; i < transcripts.length; i += 1) {
            const this_transcript = transcripts[i],
                transcript_title = document.createElement('p');

            transcript_title.innerText = 'Transcript:';
            this_transcript.setAttribute('aria-hidden', 'false');
            this_transcript.parentNode.insertBefore(transcript_title, this_transcript);
        }
    },
};

module.exports = rtp_text_only;

},{}],7:[function(require,module,exports){
// RTP resizer should mimic functionality on http://righttoparticipate.org
// of the Zeno font resizer. It should set & read from the same cookie so
// that font preferences will be smoothly shared across subdomains
const rtp_resizer = {
    // Inital values from http://righttoparticipate.org
    max_value: 40,
    min_value: 10,
    step_increase: 5,
    target_id: 'innerbody',
    cookie_name: 'fontSize',
    cookie_time: 31,

    current_value: 16,
    reset_value: 16,

    setup() {
        const pre_set_size = rtp_resizer.getCookie();

        if (pre_set_size) {
            const font_size_int = parseInt(pre_set_size, 10);
            rtp_resizer.current_value = font_size_int;
            rtp_resizer.setFontSize();

            // In case max value changes in the future, reset according to new info
            if (font_size_int > rtp_resizer.max_value) {
                rtp_resizer.max_value = font_size_int;
            }
        }

        rtp_resizer.addEventListeners();
    },

    addEventListeners() {
        const increment_btn = document.querySelector('.rtp_font_resizer_add'),
            reset_btn = document.querySelector('.rtp_font_resizer_reset');

        if (increment_btn) {
            increment_btn.addEventListener('click', rtp_resizer.increment);
        }

        if (reset_btn) {
            reset_btn.addEventListener('click', rtp_resizer.reset);
        }
    },

    increment(e) {
        e.preventDefault();

        if (rtp_resizer.current_value + rtp_resizer.step_increase <= rtp_resizer.max_value) {
            rtp_resizer.current_value += rtp_resizer.step_increase;
            rtp_resizer.setFontSize();
            rtp_resizer.setCookie();
        }
    },

    reset(e) {
        e.preventDefault();

        rtp_resizer.current_value = rtp_resizer.reset_value;
        rtp_resizer.setFontSize();
        rtp_resizer.setCookie();
    },

    setFontSize() {
        const this_element = document.querySelector(`#${rtp_resizer.target_id}`);
        let innerbody_class = '';

        // Body class is additional to functionality on original site, but needed
        // to nicely display list grid at all text sizes
        if (rtp_resizer.current_value > 25 && rtp_resizer.current_value < 35) {
            innerbody_class = 'med-large-text';
        } else if (rtp_resizer.current_value >= 35) {
            innerbody_class = 'large-text';
        }

        if (this_element) {
            this_element.className = innerbody_class;
            this_element.style.fontSize = `${rtp_resizer.current_value}px`;
        }
    },

    getCookie() {
        const cookie = document.cookie.match(`(^|;) ?${rtp_resizer.cookie_name}=([^;]*)(;|$)`);
        return cookie ? cookie[2] : null;
    },

    setCookie() {
        const d = new Date();
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * rtp_resizer.cookie_time);
        const expiry_string = d.toUTCString();
        let domain_name = window.location.hostname;

        // Setting domain should ensure this is carried across to main site
        if (domain_name.match('righttoparticipate.org')) {
            domain_name = 'righttoparticipate.org';
        }

        document.cookie = `${rtp_resizer.cookie_name}=${rtp_resizer.current_value};path=/;expires=${expiry_string};domain=${domain_name}`;
    },
};

module.exports = rtp_resizer;

},{}]},{},[4])

