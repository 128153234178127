const rtp_forms = {
    dropdown_aria_base: 'Show ordering options. Current: ',
    dropdown_time_ms: 300,
    dropdown_timeout: null,
    submit_btn: null,
    mouse_touch_timeout: null,

    setup() {
        const filter_radios = document.querySelector('.filter__filter-by'),
            order_radios = document.querySelector('.filter__order-by');
        rtp_forms.submit_btn = document.querySelector('.filters input[type=submit]');

        if (filter_radios && order_radios && rtp_forms.submit_btn) {
            rtp_forms.setupRadios(filter_radios);
            rtp_forms.setupRadios(order_radios);
            // Because the native 'select' element doesn't read out options as you navigate through
            rtp_forms.setupDropdown(order_radios);
        }

        rtp_forms.commentsFormSetup();
    },

    commentsFormSetup() {
        // Specific tweaks for comments form
        const comment_body_input = document.querySelector('.story-detail__comments #id_body'),
            comment_body_label = document.querySelector('.story-detail__comments label[for=id_body]'),
            comment_name_input = document.querySelector('.story-detail__comments #id_name'),
            comment_name_label = document.querySelector('.story-detail__comments label[for=id_name]');

        if (comment_body_input) {
            comment_body_input.placeholder = 'Write a comment...';

            if (comment_body_label) {
                comment_body_label.classList.add('hidden');
            }
        }

        if (comment_name_input) {
            comment_name_input.placeholder = 'Your name (optional)';

            if (comment_name_label) {
                comment_name_label.classList.add('hidden');
            }
        }
    },

    setupDropdown(order_radios) {
        const dropdown_btn = order_radios.querySelector('.dropdown-toggle'),
            dropdown_content = dropdown_btn.nextElementSibling,
            radios = order_radios.querySelectorAll('input[type=radio]');

        for (let i = 0; i < radios.length; i += 1) {
            radios[i].addEventListener('change', rtp_forms.setDropdownText);
        }

        if (dropdown_content) {
            dropdown_btn.setAttribute('aria-expanded', 'false');
            dropdown_btn.setAttribute('aria-haspopup', 'true');
            dropdown_btn.addEventListener('click', rtp_forms.toggleDropdown);
            dropdown_content.classList.add('hidden');
        }

        rtp_forms.setDropdownText();
    },

    setDropdownText() {
        // Reflect changes on hidden radios
        const checked_order_radio = document.querySelector('.filter__order-by input[type=radio]:checked'),
            first_option = document.querySelectorAll('.filter__order-by input[type=radio]')[0],
            dropdown_btn = document.querySelector('.filter__order-by .dropdown-toggle');
        let dropdown_text = '';

        // Check for selected radio or get text from first option
        if (checked_order_radio) {
            dropdown_text = checked_order_radio.parentNode.innerText;
        } else {
            dropdown_text = first_option.parentNode.innerText;
        }

        dropdown_btn.setAttribute('aria-label', `${rtp_forms.dropdown_aria_base}${dropdown_text}`);
        dropdown_btn.innerHTML = dropdown_text;
    },

    toggleDropdown(e) {
        const this_button = e.target,
            is_expanded = this_button.getAttribute('aria-expanded'),
            this_content = this_button.nextElementSibling;
        rtp_forms.clearDropdownTimeout();

        if (is_expanded === 'true') {
            this_button.setAttribute('aria-expanded', 'false');
            this_content.classList.remove('filter__slide-in');

            // Timeout allows for slide-in class but also using display:none to avoid needing
            // to loop through and remove tabindex on inputs hidden by other means
            rtp_forms.dropdown_timeout = setTimeout(() => {
                this_content.classList.add('hidden');
            }, rtp_forms.dropdown_time_ms);
        } else {
            this_button.setAttribute('aria-expanded', 'true');
            this_content.classList.remove('hidden');
            setTimeout(() => {
                this_content.classList.add('filter__slide-in');
            }, 0);
        }
    },

    clearDropdownTimeout() {
        if (rtp_forms.dropdown_timeout !== null) {
            clearTimeout(rtp_forms.dropdown_timeout);
            rtp_forms.dropdown_timeout = null;
        }
    },

    setupRadios(radio_group) {
        const radios = radio_group.querySelectorAll('input[type=radio]');

        for (let i = 0; i < radios.length; i += 1) {
            // JS needed because input is child of label rather than nextSibling element
            radios[i].addEventListener('change', () => {
                rtp_forms.radioChange(radio_group, 'checked');
            });

            radios[i].addEventListener('focus', () => {
                rtp_forms.radioChange(radio_group, 'focus');
            });

            radios[i].addEventListener('blur', () => {
                rtp_forms.radioChange(radio_group, 'focus');
            });

            radios[i].parentNode.addEventListener('mouseup', rtp_forms.mouseTouchSubmit);
            radios[i].parentNode.addEventListener('touchend', rtp_forms.mouseTouchSubmit);
        }

        rtp_forms.showInitialValue(radios, radio_group);
    },

    showInitialValue(radios, radio_group) {
        // Set initial radio checked values either according to url params, or first option
        let selected_val = null,
            param_name = '';

        if (radio_group.classList.contains('filter__order-by')) {
            param_name = 'order_by';
        } else {
            param_name = 'area';
        }

        selected_val = rtp_forms.getParam(param_name);
        let id_val = null;

        if (selected_val) {
            if (param_name === 'area') {
                selected_val = parseInt(selected_val, 10);
                id_val = selected_val + 1;
            } else {
                id_val = selected_val === '-created_at' ? 1 : 2;
            }
        }

        if (id_val) {
            const selected_radio_unchecked = document.querySelector(`#id_${param_name}_${id_val}:not(:checked)`),
                selected_radio = document.querySelector(`#id_${param_name}_${id_val}`);

            // Either select radio, or if selected, reflect in parentNode
            // On reload (one set of radios keeps its selection, the other not)
            if (selected_radio_unchecked) {
                selected_radio.parentNode.click();
            } else if (selected_radio) {
                selected_radio.parentNode.classList.add('checked');
            }
        } else {
            radios[0].parentNode.click();
        }
    },

    getParam(param_name) {
        const params = window.location.search,
            param_array = params.split(/[?&]/);
        let this_value = null;

        for (let i = 0; i < param_array.length; i += 1) {
            if (param_array[i].indexOf(param_name) > -1) {
                this_value = param_array[i].split('=')[1];
            }
        }

        return this_value;
    },

    radioChange(radio_group, change_type) {
        const unchecked_or_unfocused_radios = radio_group.querySelectorAll(`input[type=radio]:not(:${change_type})`),
            checked_or_focused_radio = radio_group.querySelector(`input[type=radio]:${change_type}`);

        for (let i = 0; i < unchecked_or_unfocused_radios.length; i += 1) {
            unchecked_or_unfocused_radios[i].parentNode.classList.remove(change_type);
        }

        if (checked_or_focused_radio) {
            checked_or_focused_radio.parentNode.classList.add(change_type);
        }
    },

    mouseTouchSubmit(e) {
        e.stopPropagation();

        // Only respond to mouseup or touchend, not both
        if (!rtp_forms.mouse_touch_timeout) {
            // On mouseup only, submit the form (as submit button is hidden for non-keyboard users)
            const results_holder = document.querySelector('.story-list__holder');

            if (results_holder) {
                results_holder.classList.add('faded-out');
            }

            // Allow time for fade-out, storing of selected values
            rtp_forms.mouse_touch_timeout = setTimeout(() => {
                rtp_forms.mouse_touch_timeout = null;

                if (rtp_forms.submit_btn) {
                    rtp_forms.submit_btn.click();
                }
            }, 150);
        }
    },
};

module.exports = rtp_forms;
