const rtp_expand = {
    setup() {
        const expand_btns = document.querySelectorAll('.expand-button');

        for (let i = 0; i < expand_btns.length; i += 1) {
            const this_btn = expand_btns[i],
                this_content = this_btn.nextElementSibling;
            if (this_content) {
                this_content.id = `expander-${i}`;
                this_content.setAttribute('aria-hidden', 'true');
                this_btn.setAttribute('aria-expanded', 'false');
                this_btn.setAttribute('aria-controls', `expander-${i}`);
                this_btn.addEventListener('click', rtp_expand.toggleExpand);
            }
        }
    },

    toggleExpand(e) {
        const this_btn = e.target,
            is_expanded = this_btn.getAttribute('aria-expanded'),
            content_id = this_btn.getAttribute('aria-controls'),
            this_content = document.querySelector(`#${content_id}`);

        if (is_expanded === 'true' && this_content) {
            this_btn.setAttribute('aria-expanded', 'false');
            this_content.setAttribute('aria-hidden', 'true');
        } else {
            this_btn.setAttribute('aria-expanded', 'true');
            this_content.setAttribute('aria-hidden', 'false');
        }
    },
};

module.exports = rtp_expand;
