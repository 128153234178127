const rtp_page_reload = {

    setup() {
        const reload_links = document.querySelectorAll('a.page-reload-link');

        for (let i = 0; i < reload_links.length; i += 1) {
            const this_link = reload_links[i],
                this_href = this_link.href,
                this_data_attr = this_link.getAttribute('data-target');

            this_link.id = `reload_link_${i}`;

            this_link.addEventListener('click', (e) => {
                // Prevent initial reload, store focus & scrollTop info, fade content slightly
                // and re-allow reload
                e.preventDefault();
                rtp_page_reload.storeInfo(this_link.id, this_data_attr);
                rtp_page_reload.fadeOutContent();

                // If this link is also being tracked by gtag, this will be handled separately

                if (this_link.className.indexOf('rtp-link') === -1) {
                    setTimeout(() => {
                        window.location.replace(this_href);
                    }, 0);
                }
            });
        }

        // Selected radio changes should also store focus element and scrollTop,
        // but no immediate reload
        rtp_page_reload.addReloadToRadios();

        // Check to see if info about focused element & scroll pos is stored
        rtp_page_reload.checkStoredInfo();
    },

    storeInfo(this_link_id, this_data_attr) {
        // If link has a data-target, store this id rather than link id - this
        // is where focus should go on page reload (e.g. for pagination buttons)
        // at page bottom, target story list holder
        const id_to_store = this_data_attr || this_link_id;

        if (rtp_page_reload.sessionStorageAvailable) {
            rtp_page_reload.saveFocusElement(id_to_store);

            rtp_page_reload.savePageScrollPos(this_data_attr);
        }
    },

    addReloadToRadios() {
        const radios = document.querySelectorAll('.filters input[type=radio]');

        for (let i = 0; i < radios.length; i += 1) {
            const this_radio = radios[i],
                this_id = this_radio.id;

            this_radio.addEventListener('change', () => {
                rtp_page_reload.storeInfo(this_id);
            });
        }
    },

    fadeOutContent() {
        const body = document.querySelector('body');

        if (body) {
            body.classList.add('reload-fade');
        }
    },

    saveFocusElement(focused_link_id) {
        window.sessionStorage.setItem('rol_focused_el', focused_link_id);
    },

    savePageScrollPos(possible_alternative_target_el_id) {
        let scroll_pos = window.pageYOffset || document.documentElement.scrollTop;

        if (possible_alternative_target_el_id) {
            const target_el = document.querySelector(`#${possible_alternative_target_el_id}`);

            if (target_el) {
                const bounding_rect = target_el.getBoundingClientRect(),
                    doc_scroll_top = window.pageYOffset || document.documentElement.scrollTop;
                scroll_pos = bounding_rect.top + doc_scroll_top;
            }
        }

        window.sessionStorage.setItem('rol_scroll_top', scroll_pos);
    },

    checkStoredInfo() {
        if (rtp_page_reload.sessionStorageAvailable) {
            const scroll_top = window.sessionStorage.getItem('rol_scroll_top'),
                focus_el = window.sessionStorage.getItem('rol_focused_el');

            if (scroll_top || focus_el) {
                rtp_page_reload.fadeInListHolder();
            }

            if (scroll_top) {
                document.documentElement.scrollTop = scroll_top;
                document.body.scrollTop = scroll_top;
                window.sessionStorage.setItem('rol_scroll_top', null);
            }

            setTimeout(() => {
                if (focus_el && document.querySelector(`#${focus_el}`)) {
                    document.querySelector(`#${focus_el}`).focus();
                    window.sessionStorage.setItem('rol_focused_el', null);
                }
            }, 0);
        }
    },

    fadeInListHolder() {
        // If on list view page and fetching new content
        const results_holder = document.querySelector('.story-list__holder');

        if (results_holder) {
            results_holder.classList.add('faded-out');
            results_holder.classList.add('no-transition');

            setTimeout(() => {
                results_holder.classList.remove('faded-out');
                results_holder.classList.remove('no-transition');
            }, 0);
        }
    },

    sessionStorageAvailable() {
        try {
            const storage = window.sessionStorage,
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return false;
        }
    },
};

module.exports = rtp_page_reload;
